import { createTheme } from '@mui/material/styles';
import { selectedFontCombination } from './fontConfig';

const createCustomTheme = () => {
  return createTheme({
    palette: {
      primary: { main: '#CDA2AB' },
      secondary: { main: '#f48fb1' },
      background: {
        default: '#FCFCFC',
      },
    },
    typography: {
      fontFamily: selectedFontCombination.primary,
      h1: { fontFamily: selectedFontCombination.secondary },
      h2: { fontFamily: selectedFontCombination.secondary },
      h3: { fontFamily: selectedFontCombination.secondary },
      h4: { fontFamily: selectedFontCombination.secondary },
      h5: { fontFamily: selectedFontCombination.secondary },
      h6: { fontFamily: selectedFontCombination.secondary },
      button: { fontFamily: selectedFontCombination.secondary },
      body1: { fontFamily: selectedFontCombination.primary },
      body2: { fontFamily: selectedFontCombination.primary },
      caption: { fontFamily: selectedFontCombination.primary },
      overline: { fontFamily: selectedFontCombination.primary },
      subtitle1: { fontFamily: selectedFontCombination.primary },
      subtitle2: { fontFamily: selectedFontCombination.primary },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            font-family: ${selectedFontCombination.primary};
            background-color: #FCFCFC;
          }
          .email-collection {
            font-family: ${selectedFontCombination.primary};
          }
          .email-collection h3 {
            font-family: ${selectedFontCombination.secondary};
            color: #524C4C;
          }
          .email-collection input {
            font-family: ${selectedFontCombination.primary};
          }
          .email-collection button {
            font-family: ${selectedFontCombination.secondary};
          }
        `,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: selectedFontCombination.secondary,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: selectedFontCombination.primary,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            backgroundColor: '#FCFCFC',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontFamily: selectedFontCombination.primary,
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
          icon: {
            color: '#524C4C',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: '1px solid #D4A5A5',
            borderRadius: '4px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: selectedFontCombination.primary,
            '&:hover': {
              backgroundColor: '#F7E8E8',
            },
            '&.Mui-selected': {
              backgroundColor: '#D4A5A5',
              color: '#524C4C',
              '&:hover': {
                backgroundColor: '#C59494',
              },
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#524C4C',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: '#FCFCFC',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: '#D4A5A5',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            backgroundColor: '#FCFCFC',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#FCFCFC',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
};

export default createCustomTheme;