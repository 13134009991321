import React from 'react';
import { Box, Container, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const FAQ = () => {
  const theme = useTheme();

  const faqData = [
    {
      question: "Wat is Skinloe?",
      answer: "Skinloe is een gepersonaliseerde huidverzorgingsroutine generator. We hebben ook een AI-huidverzorgingsassistent chat, en functies waarmee je je routines kunt opslaan, huidverzorgingsproducten kunt beoordelen en meer."
    },
    {
      question: "Hoe werkt het?",
      answer: "Je voert je huidtype, doelen en voorkeuren in, en wij genereren met behulp van AI een op maat gemaakte huidverzorgingsroutine voor jou."
    },
    {
      question: "Wat is de Skinloe AI assistent?",
      answer: "De AI-assistent is een aangepaste chatbot, ontworpen om je te helpen met al je huidverzorgingsbehoeften. De technologie is gebaseerd op het gpt-4o-mini model."
    },
    {
      question: "Kan iemand de berichten zien die ik naar de chatbot typ?",
      answer: "Nee, niemand anders dan jij kan je gesprekken met de AI-assistent zien."
    },
    {
      question: "Zijn de productlinks gesponsord?",
      answer: "Ja, we voegen affiliate links toe in de gegenereerde routines en verdienen een kleine commissie op verkopen. Als u skinloe.nl wilt steunen, koop dan iets via de affiliate links <3"
    },
    {
      question: "Komen er nieuwe functies naar Skinloe?",
      answer: "Ja! Meld u aan via het formulier op de homepage om updates van Skinloe te ontvangen."
    },
    {
      question: "Wie zit er achter Skinloe?",
      answer: "Een klein en enthousiast team gevestigd in Den Haag!"
    },
    {
      question: "Meer vragen?",
      answer: "Neem contact op via hallo@skinloe.nl"
    }
  ];

  const styles = {
    container: {
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
    },
    logo: {
      width: '250px',
      marginBottom: '20px',
    },
    faqBox: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '32px',
    },
    title: {
      color: '#524C4C',
      marginBottom: '20px',
      fontFamily: theme.typography.h4.fontFamily,
    },
    accordion: {
      marginBottom: '10px',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    accordionSummary: {
      backgroundColor: '#D4A5A5',
      '&:hover': {
        backgroundColor: '#C59494',
      },
    },
    question: {
      color: '#524C4C',
      fontFamily: theme.typography.body1.fontFamily,
    },
    answer: {
      color: '#524C4C',
      textAlign: 'left',
      fontFamily: theme.typography.body1.fontFamily,
    },
    expandIcon: {
      color: '#524C4C',
    },
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Box>
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Skinloe Logo" style={styles.logo} />
        </Link>
      </Box>
      <Box sx={styles.faqBox}>
        <Typography variant="h4" component="h2" gutterBottom sx={styles.title}>
          Veelgestelde vragen
        </Typography>
        {faqData.map((faq, index) => (
          <Accordion key={index} sx={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              sx={styles.accordionSummary}
            >
              <Typography sx={styles.question}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={styles.answer}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQ;