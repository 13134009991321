import React, { useState, useEffect } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Typography, CircularProgress, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Modal, useMediaQuery } from '@mui/material';
import createCustomTheme from './theme';
import { generateRoutine, saveRoutine, fetchMoreProducts } from './api';
import EmailCollection from './EmailCollection';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { useUser, SignInButton } from "@clerk/clerk-react";
import FAQ from './FAQ';
import Privacyverklaring from './Privacyverklaring';
import Contact from './Contact';
import TopNav from './TopNav';
import ComingSoonPage from './ComingSoonPage';
import SavedRoutines from './SavedRoutines';
import RoutineView from './RoutineView';
import { trackEvent, initializeAnalytics } from './Analytics';
import SkinloeAIAssistent from './SkinloeAIAssistent';
import { CssBaseline } from '@mui/material';
import Cookies from './Cookies';
import Fact from './Fact';
import skincareFacts from './skincarefacts.json';
import { isMobilePhone } from './deviceDetection';
import NotFound from './NotFound';
import './MobileStyles.css';

const customTheme = createCustomTheme();

console.log('Custom theme:', customTheme);

const AuthCheck = ({ children }) => {
  const { isSignedIn } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!isSignedIn) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center', backgroundColor: '#FCFCFC' }}>
        <Box mt={4}>
          <Link to="/">
            <img 
              src={`${process.env.PUBLIC_URL}/logo.png`} 
              alt="Skinloe Logo" 
              style={{ 
                width: isMobile ? 'auto' : '350px',
                maxWidth: '100%',
                height: 'auto',
                maxHeight: isMobile ? '150px' : 'auto',
                marginBottom: '20px'
              }} 
            />
          </Link>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography 
            variant="h6" 
            gutterBottom 
            sx={{ 
              fontFamily: theme.typography.h6.fontFamily,
              color: '#524C4C',
              marginBottom: '20px'
            }}
          >
            Je moet ingelogd zijn om deze pagina te bekijken.
          </Typography>
          <SignInButton mode="modal">
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: '#D4A5A5',
                color: '#524C4C',
                fontFamily: theme.typography.button.fontFamily,
                '&:hover': {
                  backgroundColor: '#C59494',
                },
              }}
            >
              Inloggen
            </Button>
          </SignInButton>
        </Box>
      </Container>
    );
  }

  return children;
};

const MainContent = ({ skinType, setSkinType, skinGoals, handleSkinGoalsChange, complexity, setComplexity, ageRange, setAgeRange, loading, routine, handleGenerateRoutine, open, handleClose, theme, isMobile }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [routineName, setRoutineName] = useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [moreProducts, setMoreProducts] = useState([]);

  const handleSaveDialogOpen = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveDialogClose = () => {
    setSaveDialogOpen(false);
    setRoutineName('');
  };

  const handleSaveRoutine = async () => {
    if (!user || !routine || !routineName) return;

    try {
      const routineData = {
        userEmail: user.primaryEmailAddress.emailAddress,
        routineName,
        skinType,
        skinGoals,
        routineComplexity: complexity,
        ageRange,
        originalRoutineId: routine._id
      };
      console.log('Routine data to be saved:', routineData);
      
      const savedRoutine = await saveRoutine(routineData);
      console.log('Saved routine response:', savedRoutine);
      
      trackEvent('Routine', 'Save', routineName);
      
      handleSaveDialogClose();
      setSuccessSnackbarOpen(true);
      setTimeout(() => navigate('/opgeslagen-routines'), 2000);
    } catch (error) {
      console.error('Fout bij het opslaan van de routine:', error);
      alert("Kon de routine niet opslaan. Probeer het opnieuw.");
    }
  };

  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbarOpen(false);
  };

  const handleOpenModal = async () => {
    console.log('handleOpenModal called');
    if (routine && routine.content) {
      const categories = ['Reiniger', 'Toner', 'Serum', 'Moisturizer', 'Zonnebrandcrème', 'Nachtcrème', 'Gezichtsolie', 'Oogcrème', 'Hydraterend masker', 'Essence', 'Hydratatiewater', 'Exfoliant', 'Make-up remover', 'Reinigingsolie'];
      const usedCategories = categories.filter(category => 
        new RegExp(`\\b${category}\\b`, 'i').test(routine.content)
      );
      try {
        const products = await fetchMoreProducts(usedCategories);
        console.log('Fetched more products:', products);
        setMoreProducts(products);
        setOpenModal(true);
      } catch (error) {
        console.error('Fout bij het ophalen van meer producten:', error);
        alert('Er is een fout opgetreden bij het ophalen van meer producten. Probeer het later opnieuw.');
      }
    } else {
      console.log('No routine available');
      alert('Er is nog geen routine gegenereerd. Genereer eerst een routine voordat je meer producten bekijkt.');
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', backgroundColor: '#FCFCFC', position: 'relative' }}>
      <Box mt={4} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Link to="/">
          <img 
            src={`${process.env.PUBLIC_URL}/logo.png`} 
            alt="Skinloe Logo" 
            style={{ 
              width: isMobile ? 'auto' : '350px',
              maxWidth: '100%',
              height: 'auto',
              maxHeight: isMobile ? '150px' : 'auto',
              marginBottom: '20px'
            }} 
          />
        </Link>
      </Box>
      
      <Typography variant="h6" gutterBottom style={{ fontFamily: theme.typography.h6.fontFamily }}>
        Jouw huidroutine in 1 minuut
      </Typography>
      <Box className="input-container" mb={2}>
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel style={{ fontFamily: theme.typography.body1.fontFamily }}>Huidtype</InputLabel>
          <Select value={skinType} onChange={(e) => setSkinType(e.target.value)} style={{ fontFamily: theme.typography.body1.fontFamily }}>
            <MenuItem value=""><em>Geen</em></MenuItem>
            <MenuItem value="Vettig">Vettig</MenuItem>
            <MenuItem value="Droog">Droog</MenuItem>
            <MenuItem value="Gecombineerd">Gecombineerd</MenuItem>
            <MenuItem value="Gevoelig">Gevoelig</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 200, position: 'relative' }}>
          <InputLabel style={{ fontFamily: theme.typography.body1.fontFamily }}>Huidoelen</InputLabel>
          <Select
            multiple
            value={skinGoals}
            onChange={handleSkinGoalsChange}
            renderValue={(selected) => 
              selected.length > 1 ? `${selected.length} gekozen` : selected[0]
            }
            style={{ fontFamily: theme.typography.body1.fontFamily }}
          >
            <MenuItem value="Hydratatie">Hydratatie</MenuItem>
            <MenuItem value="Anti-Veroudering">Anti-Veroudering</MenuItem>
            <MenuItem value="Verheldering">Verheldering</MenuItem>
            <MenuItem value="Porieverzorging">Porieverzorging</MenuItem>
            <MenuItem value="Acne">Acne</MenuItem>
          </Select>
          {skinGoals.length > 0 && (
            <Box
              sx={{
                position: 'absolute',
                top: -8,
                right: -8,
                backgroundColor: '#D4A5A5',
                color: '#524C4C',
                borderRadius: '50%',
                padding: '2px 6px',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                zIndex: 1,
              }}
            >
              {skinGoals.length}
            </Box>
          )}
        </FormControl>
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel style={{ fontFamily: theme.typography.body1.fontFamily }}>Complexiteit</InputLabel>
          <Select value={complexity} onChange={(e) => setComplexity(e.target.value)} style={{ fontFamily: theme.typography.body1.fontFamily }}>
            <MenuItem value=""><em>Geen</em></MenuItem>
            <MenuItem value="Basis">Basis</MenuItem>
            <MenuItem value="Gemiddeld">Gemiddeld</MenuItem>
            <MenuItem value="Gevorderd">Gevorderd</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel style={{ fontFamily: theme.typography.body1.fontFamily }}>Leeftijdsgroep</InputLabel>
          <Select value={ageRange} onChange={(e) => setAgeRange(e.target.value)} style={{ fontFamily: theme.typography.body1.fontFamily }}>
            <MenuItem value=""><em>Geen</em></MenuItem>
            <MenuItem value="20-30">20-30</MenuItem>
            <MenuItem value="30-40">30-40</MenuItem>
            <MenuItem value="40-50">40-50</MenuItem>
            <MenuItem value="50+">50+</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="button-container">
        <Button 
          className="custom-button" 
          onClick={handleGenerateRoutine} 
          disabled={loading}
          sx={{
            fontFamily: theme.typography.button.fontFamily,
            backgroundColor: '#D4A5A5',
            color: '#524C4C',
            '&:hover': {
              backgroundColor: '#C59494',
            },
            borderRadius: '25px',
            padding: '10px 20px',
            width: 'auto',
            whiteSpace: 'nowrap',
            margin: '5px',
            fontSize: '0.875rem',
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Mijn routine genereren'}
        </Button>
      </Box>
      {routine && (
        <Box mt={4} p={2} style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
          <Typography variant="h6" style={{ fontFamily: theme.typography.h6.fontFamily }}>Jouw Huidverzorgingsroutine:</Typography>
          <div 
            className="routine-content"
            style={{ textAlign: 'left', marginTop: '16px' }} 
            dangerouslySetInnerHTML={{ __html: routine.content }} 
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button 
              onClick={handleOpenModal}
              sx={{
                backgroundColor: '#D4A5A5',
                color: '#FCFCFC',
                fontFamily: theme.typography.button.fontFamily,
                '&:hover': {
                  backgroundColor: '#C59494',
                },
              }}
            >
              Bekijk meer aanbevolen producten
            </Button>
          </Box>
          {user && (
            <Button
              className="custom-button"
              onClick={handleSaveDialogOpen}
              style={{ marginTop: '16px', fontFamily: theme.typography.button.fontFamily }}
            >
              Routine opslaan
            </Button>
          )}
        </Box>
      )}
      <Dialog
        open={saveDialogOpen}
        onClose={handleSaveDialogClose}
        PaperProps={{
          sx: {
            backgroundColor: '#FCFCFC',
            borderRadius: '8px',
          }
        }}
      >
        <DialogTitle style={{ fontFamily: theme.typography.h6.fontFamily }}>Routine opslaan</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: theme.typography.body1.fontFamily }}>
            Voer een naam in voor deze routine:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Routine naam"
            type="text"
            fullWidth
            variant="outlined"
            value={routineName}
            onChange={(e) => setRoutineName(e.target.value)}
            sx={{ width: '300px' }}
            style={{ fontFamily: theme.typography.body1.fontFamily }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDialogClose} color="primary" style={{ fontFamily: theme.typography.button.fontFamily }}>
            Annuleren
          </Button>
          <Button onClick={handleSaveRoutine} color="primary" disabled={!routineName} style={{ fontFamily: theme.typography.button.fontFamily }}>
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success" sx={{ width: '100%', backgroundColor: customTheme.palette.primary.main, color: '#fff' }} style={{ fontFamily: theme.typography.body1.fontFamily }}>
          Routine succesvol opgeslagen!
        </Alert>
      </Snackbar>
      
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="meer-aanbevolen-producten"
        aria-describedby="extra-product-aanbevelingen"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
          '& a': {
            color: 'inherit',
            textDecoration: 'none',
            borderBottom: '1px dotted currentColor',
            transition: 'border-bottom 0.3s ease',
            '&:hover': {
              borderBottom: '1px solid currentColor',
            },
          },
        }}>
          <Typography id="meer-aanbevolen-producten" variant="h5" component="h2">
            Meer aanbevolen producten
          </Typography>
          <Typography id="extra-product-aanbevelingen" sx={{ mt: 2 }}>
            Hier zijn enkele aanvullende productaanbevelingen voor je huidverzorgingsroutine:
          </Typography>
          {moreProducts.map((category) => (
            <Box key={category.category} mt={2}>
              <Typography variant="h6" component="h2">
                {category.category}
              </Typography>
              {category.products.map((product) => (
                <Box key={product._id} mt={1}>
                  <a href={product.affiliateLink} target="_blank" rel="noopener noreferrer">
                    {product.productName}
                  </a>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Modal>
    </Container>
  );
};

const Footer = ({ theme }) => (
  <footer style={{
    padding: '10px 0',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    borderTop: '1px solid #CDA2AB',
    textAlign: 'center'
  }}>
    <Typography variant="body2" style={{ 
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: '0.9em',
      lineHeight: '1.5',
      padding: '5px 0'
    }}>
      <Link to="/faq" className="footer-link">FAQ</Link>
      <Link to="/privacyverklaring" className="footer-link">Privacyverklaring</Link>
      <Link to="/cookies" className="footer-link">Cookiebeleid</Link>
      <Link to="/contact" className="footer-link">Contact</Link>
    </Typography>
  </footer>
);

const AppContent = () => {
  const theme = useTheme();
  console.log('Theme in AppContent:', theme);

  const [skinType, setSkinType] = useState('');
  const [skinGoals, setSkinGoals] = useState([]);
  const [complexity, setComplexity] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [loading, setLoading] = useState(false);
  const [routine, setRoutine] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { isSignedIn } = useUser();
  const [saveRoutinePopupOpen, setSaveRoutinePopupOpen] = useState(false);
  const [showEmailCollection, setShowEmailCollection] = useState(true);
  const [showFact, setShowFact] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    initializeAnalytics();
  }, []);

  useEffect(() => {
    const shouldShowEmailCollection = location.pathname !== '/skinloe-ai-assistent';
    setShowEmailCollection(shouldShowEmailCollection);
  }, [location.pathname]);

  useEffect(() => {
    const checkMobile = () => {
      const mobile = isMobilePhone();
      setIsMobile(mobile);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSkinGoalsChange = (event) => {
    setSkinGoals(event.target.value);
  };

  const handleGenerateRoutine = async () => {
    if (!skinType || skinGoals.length === 0 || !complexity || !ageRange) {
      setSnackbarOpen(true);
      return;
    }
    setLoading(true);
    setRoutine(null);
    try {
      const data = await generateRoutine(skinType, skinGoals, complexity, ageRange);
      console.log('Generated routine data:', data);
      setRoutine({
        _id: data._id,
        content: data.routine
      });
      
      trackEvent('Routine', 'Generate', `${skinType}-${skinGoals.join(',')}-${complexity}-${ageRange}`);
      
      if (isSignedIn && !localStorage.getItem('hasSeenSaveRoutinePopup')) {
        localStorage.setItem('hasSeenSaveRoutinePopup', 'true');
        setSaveRoutinePopupOpen(true);
      }

      // Show the fact after generating a routine
      setShowFact(true);
    } catch (error) {
      console.error('Error generating routine:', error);
    }
    setLoading(false);
  };

  const handleClose = () => setOpen(false);
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSaveRoutinePopupClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSaveRoutinePopupOpen(false);
  };

  return (
    <Box 
      id="root" 
      className="app-container" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh'
      }}
    >
      <Box sx={{ 
        flexGrow: 1
      }}>
        <TopNav isMobile={isMobile} />
        <Box className="main-wrapper">
          <Box className="content-container">
            <Routes>
              <Route path="/" element={
                <MainContent
                  skinType={skinType}
                  setSkinType={setSkinType}
                  skinGoals={skinGoals}
                  handleSkinGoalsChange={handleSkinGoalsChange}
                  complexity={complexity}
                  setComplexity={setComplexity}
                  ageRange={ageRange}
                  setAgeRange={setAgeRange}
                  loading={loading}
                  routine={routine}
                  handleGenerateRoutine={handleGenerateRoutine}
                  open={open}
                  handleClose={handleClose}
                  theme={theme}
                  isMobile={isMobile}
                />
              } />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacyverklaring" element={<Privacyverklaring />} />
              <Route path="/cookies" element={<Cookies />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/opgeslagen-routines" element={
                <AuthCheck>
                  <SavedRoutines />
                </AuthCheck>
              } />
              <Route path="/beoordeelde-producten" element={
                <AuthCheck>
                  <ComingSoonPage />
                </AuthCheck>
              } />
              <Route path="/routine/:id" element={
                <AuthCheck>
                  <RoutineView />
                </AuthCheck>
              } />
              <Route path="/skinloe-ai-assistent" element={
                <SkinloeAIAssistent isSignedIn={isSignedIn} isMobile={isMobile} />
              } />
              <Route path="*" element={<NotFound isMobile={isMobile} />} />
            </Routes>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ 
        width: '100%', 
        maxWidth: '600px', 
        margin: '0 auto',
        mt: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        {showEmailCollection && (
          <ThemeProvider theme={theme}>
            <EmailCollection />
          </ThemeProvider>
        )}
        <Footer theme={theme} />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="warning" 
          sx={{ 
            width: '100%', 
            backgroundColor: '#D4A5A5',
            color: '#524C4C',
            '& .MuiAlert-icon': {
              color: '#524C4C',
            },
          }}
          style={{ fontFamily: theme.typography.body1.fontFamily }}
        >
          Selecteer alle parameters om een routine te genereren.
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveRoutinePopupOpen}
        autoHideDuration={6000}
        onClose={handleSaveRoutinePopupClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSaveRoutinePopupClose} severity="info" sx={{ width: '100%', backgroundColor: theme.palette.primary.main, color: '#fff' }} style={{ fontFamily: theme.typography.body1.fontFamily }}>
          Je kunt je routine opslaan met de knop onderaan de routine.
        </Alert>
      </Snackbar>
      
      {showFact && !isMobile && (
        <Fact 
          facts={skincareFacts} 
          onClose={() => setShowFact(false)} 
          isMobile={isMobile}
        />
      )}
    </Box>
  );
};

const App = () => {
  const theme = createCustomTheme();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(isMobilePhone());
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className={`app-container ${isMobile ? 'mobile' : ''}`}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContent />
      </ThemeProvider>
    </div>
  );
};

export default App;