import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Box, useTheme, IconButton, Tooltip, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { UserButton, SignInButton, useUser } from "@clerk/clerk-react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import SaveIcon from '@mui/icons-material/Save';
import StarIcon from '@mui/icons-material/Star';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import useMediaQuery from '@mui/material/useMediaQuery';

const TopNav = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isSignedIn } = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const linkStyle = {
    textDecoration: 'none',
    fontFamily: theme.typography.body1.fontFamily,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    borderRadius: '4px',
    marginLeft: '10px',
    color: '#524C4C !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: '#D4A5A5 !important',
    },
    '& .MuiButton-startIcon': {
      color: 'inherit !important',
    },
    '& .MuiSvgIcon-root': {
      color: 'inherit !important',
    },
    '& .MuiButton-label': {
      color: 'inherit !important',
    },
  };

  const homeIconStyle = {
    ...linkStyle,
    marginLeft: 'auto',
    padding: '8px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  };

  const handleHomeClick = () => {
    navigate('/');
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={handleHomeClick}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={RouterLink} to="/skinloe-ai-assistent">
          <ListItemIcon><ChatIcon /></ListItemIcon>
          <ListItemText primary="Skinloe AI Assistent" />
        </ListItem>
        <ListItem button component={RouterLink} to="/opgeslagen-routines">
          <ListItemIcon><SaveIcon /></ListItemIcon>
          <ListItemText primary="Opgeslagen Routines" />
        </ListItem>
        <ListItem button component={RouterLink} to="/beoordeelde-producten">
          <ListItemIcon><StarIcon /></ListItemIcon>
          <ListItemText primary="Beoordeelde Producten" />
        </ListItem>
        {!isSignedIn && (
          <ListItem>
            <SignInButton mode="modal">
              <Button 
                startIcon={<LoginIcon />}
                sx={{ 
                  fontWeight: 'bold',
                  fontFamily: theme.typography.button.fontFamily,
                  color: '#524C4C !important',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                    color: '#D4A5A5 !important',
                  },
                }}
              >
                Inloggen
              </Button>
            </SignInButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {isMobile ? (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isSignedIn ? (
                <UserButton />
              ) : (
                <SignInButton mode="modal">
                  <Button 
                    startIcon={<LoginIcon />}
                    sx={{ 
                      fontWeight: 'bold',
                      fontFamily: theme.typography.button.fontFamily,
                      color: '#524C4C !important',
                      '&:hover': {
                        backgroundColor: 'transparent !important',
                        color: '#D4A5A5 !important',
                      },
                    }}
                  >
                    Inloggen
                  </Button>
                </SignInButton>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Praat met Sharon" arrow>
                <Button
                  component={RouterLink}
                  to="/skinloe-ai-assistent"
                  startIcon={<ChatIcon />}
                  sx={{
                    ...linkStyle,
                    backgroundColor: '#D4A5A5',
                    color: '#FFFFFF !important',
                    '&:hover': {
                      backgroundColor: '#C59494',
                    },
                    '&:hover .MuiButton-label': {
                      color: '#FCFCFC !important',
                    },
                    '& .MuiButton-label': {
                      color: '#FFFFFF !important',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#FFFFFF !important',
                    },
                    '&:hover .MuiSvgIcon-root': {
                      color: '#FCFCFC !important',
                    },
                  }}
                >
                  Skinloe AI Assistent
                </Button>
              </Tooltip>
              <Button
                component={RouterLink}
                to="/opgeslagen-routines"
                startIcon={<SaveIcon />}
                sx={linkStyle}
              >
                Opgeslagen Routines
              </Button>
              <Button
                component={RouterLink}
                to="/beoordeelde-producten"
                startIcon={<StarIcon />}
                sx={linkStyle}
              >
                Beoordeelde Producten
              </Button>
              <Tooltip title="Terug naar de startpagina" arrow>
                <IconButton
                  onClick={handleHomeClick}
                  sx={homeIconStyle}
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;