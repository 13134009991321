import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const theme = useTheme();

  const styles = {
    container: {
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
    },
    logo: {
      width: '250px',
      marginBottom: '20px',
    },
    contentBox: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '32px',
    },
    title: {
      color: '#524C4C',
      marginBottom: '20px',
      fontFamily: theme.typography.h4.fontFamily,
    },
    content: {
      color: '#524C4C',
      textAlign: 'center',
      fontFamily: theme.typography.body1.fontFamily,
      marginBottom: '20px',
    },
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Box>
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Skinloe Logo" style={styles.logo} />
        </Link>
      </Box>
      <Box sx={styles.contentBox}>
        <Typography variant="h4" component="h1" gutterBottom sx={styles.title}>
          Oeps! Pagina niet gevonden
        </Typography>
        <Typography sx={styles.content}>
          De pagina die je zoekt bestaat niet. Klik op het Skinloe logo hierboven om terug te gaan naar de homepage.
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;