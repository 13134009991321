import axios from 'axios';

export const generateRoutine = async (skinType, skinGoals, routineComplexity, ageRange) => {
  console.log('API Request Parameters:', { skinType, skinGoals, routineComplexity, ageRange });
  try {
    const response = await axios.post('/api/generate-routine', {
      skinType,
      skinGoals,
      routineComplexity,
      ageRange,
    });
    console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error generating routine:', error);
    throw error;
  }
};

export const collectEmail = async (email) => {
  try {
    const response = await axios.post('/api/collect-email', { email });
    return response.data;
  } catch (error) {
    console.error('Error collecting email:', error);
    throw error;
  }
};

export const saveRoutine = async (routineData) => {
  console.log('Sending routine data:', routineData);
  try {
    const response = await axios.post('/api/save-routine', routineData);
    console.log('Save routine response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in saveRoutine:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchSavedRoutines = async (userEmail) => {
  try {
    console.log("Fetching routines for email:", userEmail);
    const response = await axios.get(`/api/saved-routines/${encodeURIComponent(userEmail)}`);
    console.log("Raw API response:", response);
    console.log("API response data:", response.data);
    return response.data;
  } catch (error) {
    console.error('Fout bij het ophalen van opgeslagen routines:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};

export const updateRoutineName = async (routineId, newName) => {
  try {
    const response = await axios.put(`/api/update-routine-name/${routineId}`, { newName });
    return response.data;
  } catch (error) {
    console.error('Error updating routine name:', error);
    throw error;
  }
};

export const testAPI = async () => {
  try {
    const response = await axios.get('/api/test');
    console.log('Test API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error testing API:', error);
    throw error;
  }
};

export const fetchRoutineById = async (id) => {
  console.log(`Fetching routine with ID: ${id}`);
  try {
    const response = await axios.get(`/api/routine/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log("Response headers:", response.headers);
    console.log("Response data:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching routine:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const createThread = async () => {
  try {
    const response = await axios.post('/api/create-thread');
    return response.data.threadId;
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

export const addMessage = async (threadId, content) => {
  try {
    await axios.post('/api/add-message', { threadId, content });
  } catch (error) {
    console.error('Error adding message:', error);
    throw error;
  }
};

export const runAssistant = async (threadId) => {
  try {
    const response = await axios.post('/api/run-assistant', { threadId });
    return response.data.response;
  } catch (error) {
    console.error('Error running assistant:', error);
    throw error;
  }
};

export const submitFeedback = async (userId, userEmail, feedback) => {
  try {
    const response = await axios.post('/api/submit-feedback', {
      userId,
      userEmail,
      feedback
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting feedback:', error);
    throw error;
  }
};

export const fetchMoreProducts = async (categories) => {
  console.log('Fetching more products for categories:', categories);
  try {
    const response = await axios.get('/api/more-products', {
      params: { categories: categories.join(',') }
    });
    console.log('Fetched more products response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fout bij het ophalen van meer producten:', error);
    throw error;
  }
};