import React from 'react';
import { Box, Container, Typography, useTheme, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Cookies = () => {
  const theme = useTheme();

  const styles = {
    container: {
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
    },
    logo: {
      width: '250px',
      marginBottom: '20px',
    },
    contentBox: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '32px',
    },
    title: {
      color: '#524C4C',
      marginBottom: '20px',
      fontFamily: theme.typography.h4.fontFamily,
    },
    content: {
      color: '#524C4C',
      textAlign: 'left',
      fontFamily: theme.typography.body1.fontFamily,
    },
    section: {
      marginBottom: '16px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: '8px',
      fontFamily: theme.typography.h6.fontFamily,
    },
    cookieButton: {
      padding: '8px 30px',
      background: '#F8F9FA',
      color: '#858A8F',
      border: '1px solid #DEE2E6',
      boxSizing: 'border-box',
      borderRadius: '2px',
      cursor: 'pointer',
      marginTop: '20px',
      fontFamily: theme.typography.button.fontFamily,
    },
    linkList: {
      listStyleType: 'none',
      padding: 0,
      marginTop: '16px',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Box>
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Skinloe Logo" style={styles.logo} />
        </Link>
      </Box>
      <Box sx={styles.contentBox}>
        <Typography variant="h4" component="h1" gutterBottom sx={styles.title}>
          Cookiebeleid
        </Typography>
        <Box sx={styles.content}>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Ingangsdatum: 22-Jul-2024</Typography>
            <Typography sx={styles.sectionTitle}>Laatst bijgewerkt: 22-Jul-2024</Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Wat zijn cookies?</Typography>
            <Typography>
              Dit Cookiebeleid legt uit wat cookies zijn en hoe we ze gebruiken, de soorten cookies die we gebruiken, de informatie die we verzamelen met behulp van cookies en hoe die informatie wordt gebruikt, en hoe u de cookie-instellingen kunt beheren.
            </Typography>
            <Typography sx={{ marginTop: '8px' }}>
              Cookies zijn kleine tekstbestanden die worden gebruikt om kleine stukjes informatie op te slaan. Ze worden op uw apparaat opgeslagen wanneer de website in uw browser wordt geladen. Deze cookies helpen ons om de website goed te laten functioneren, deze veiliger te maken, een betere gebruikerservaring te bieden en te begrijpen hoe de website presteert en te analyseren wat werkt en waar verbetering nodig is.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Hoe gebruiken we cookies?</Typography>
            <Typography>
              Zoals de meeste online diensten gebruikt onze website first-party en third-party cookies voor verschillende doeleinden. First-party cookies zijn meestal noodzakelijk voor het juist functioneren van de website en verzamelen geen persoonlijk identificeerbare gegevens.
            </Typography>
            <Typography sx={{ marginTop: '8px' }}>
              De third-party cookies die op onze website worden gebruikt, zijn voornamelijk bedoeld om te begrijpen hoe de website presteert, hoe u met onze website interageert, onze diensten veilig te houden, advertenties te tonen die relevant voor u zijn, en u al met al een betere en verbeterde gebruikerservaring te bieden en uw toekomstige interacties met onze website te versnellen.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Soorten cookies die we gebruiken</Typography>
            <div className="cky-audit-table-element"></div>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Cookievoorkeuren beheren</Typography>
            <Button
              className="cky-banner-element"
              sx={styles.cookieButton}
              onClick={() => window.cookieyes.show()}
            >
              Cookie-instellingen
            </Button>
            <Typography sx={{ marginTop: '16px' }}>
              U kunt uw cookievoorkeuren op elk moment wijzigen door op de bovenstaande knop te klikken. Hiermee kunt u de cookietoestemmingsbanner opnieuw bekijken en uw voorkeuren wijzigen of uw toestemming direct intrekken.
            </Typography>
            <Typography sx={{ marginTop: '16px' }}>
              Daarnaast bieden verschillende browsers verschillende methoden om cookies te blokkeren en te verwijderen die door websites worden gebruikt. U kunt de instellingen van uw browser wijzigen om cookies te blokkeren/verwijderen. Hieronder vindt u links naar de ondersteuningsdocumenten over het beheren en verwijderen van cookies voor de belangrijkste webbrowsers.
            </Typography>
            <ul style={styles.linkList}>
              <li><a href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noopener noreferrer" style={styles.link}>Chrome</a></li>
              <li><a href="https://support.apple.com/nl-nl/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer" style={styles.link}>Safari</a></li>
              <li><a href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen" target="_blank" rel="noopener noreferrer" style={styles.link}>Firefox</a></li>
              <li><a href="https://support.microsoft.com/nl-nl/windows/cookies-verwijderen-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target="_blank" rel="noopener noreferrer" style={styles.link}>Internet Explorer</a></li>
            </ul>
            <Typography>
              Als u een andere webbrowser gebruikt, raadpleeg dan de officiële ondersteuningsdocumenten van uw browser.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Cookies;