import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react';
import { nlNL } from '@clerk/localizations';

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const CLERK_DOMAIN = process.env.REACT_APP_CLERK_DOMAIN;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

if (!CLERK_DOMAIN) {
  throw new Error("Missing Clerk Domain");
}

const AppWrapper = () => (
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={PUBLISHABLE_KEY} 
      domain={CLERK_DOMAIN}
      localization={nlNL}
      appearance={{
        elements: {
          formButtonPrimary: {
            backgroundColor: '#D4A5A5',
            '&:hover': { backgroundColor: '#C59494' },
          },
        },
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

reportWebVitals();