import React, { useState } from 'react';
import { Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar, SnackbarContent } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useUser } from "@clerk/clerk-react";
import { submitFeedback } from './api';
import styles from './FeedbackForm.module.css';

const FeedbackForm = () => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [charCount, setCharCount] = useState(0);
  const maxChars = 500;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user } = useUser();

  const handleFeedbackChange = (e) => {
    const input = e.target.value;
    if (input.length <= maxChars) {
      setFeedback(input);
      setCharCount(input.length);
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (feedback.trim()) {
      try {
        await submitFeedback(user.id, user.primaryEmailAddress.emailAddress, feedback);
        setFeedback('');
        handleClose();
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error submitting feedback:', error);
        alert('Er is een fout opgetreden bij het versturen van je feedback.');
      }
    }
  };

  return (
    <>
      <div className={styles.feedbackButtonContainer}>
        <Fab
          color="primary"
          aria-label="feedback"
          className={styles.fabButton}
          onClick={handleClickOpen}
        >
          <ChatBubbleOutlineIcon />
        </Fab>
      </div>
      <Dialog 
        open={open} 
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        classes={{paper: styles.dialog}}
      >
        <DialogTitle className={styles.dialogTitle}>
         Heeft u feedback over Sharon? Schrijf het hier!
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <textarea
            id="feedback"
            placeholder="Je feedback"
            value={feedback}
            onChange={handleFeedbackChange}
            className={styles.textArea}
            maxLength={maxChars}
          />
          <div className={styles.charCount}>
            {maxChars - charCount} karakters over
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button 
            onClick={handleClose} 
            className={styles.cancelButton}
          >
            Annuleren
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            className={styles.submitButton}
            disabled={charCount === 0 || charCount > maxChars}
          >
            Versturen
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <SnackbarContent
          className={styles.snackbar}
          message="Bedankt voor je feedback!"
        />
      </Snackbar>
    </>
  );
};

export default FeedbackForm;