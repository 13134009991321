import React, { useState } from 'react';
import { Box, Container, Typography, useTheme, Button, Snackbar, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { submitFeedback } from './api';
import { useUser } from "@clerk/clerk-react";

const Contact = () => {
  const theme = useTheme();
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user } = useUser();

  const styles = {
    container: {
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
    },
    logo: {
      width: '250px',
      marginBottom: '20px',
    },
    contentBox: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '32px',
    },
    title: {
      color: '#524C4C',
      marginBottom: '20px',
      fontFamily: theme.typography.h4.fontFamily,
    },
    content: {
      color: '#524C4C',
      textAlign: 'center',
      fontFamily: theme.typography.body1.fontFamily,
      marginBottom: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: '20px',
    },
    textArea: {
      width: '100%',
      minHeight: '100px',
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: '16px',
      resize: 'vertical',
      backgroundColor: '#FCFCFC',
    },
    submitButton: {
      marginTop: '20px',
      backgroundColor: '#d4a4a4',
      color: '#524C4C',
      '&:hover': {
        backgroundColor: '#c59494',
      },
      '&:disabled': {
        backgroundColor: '#e0e0e0',
        color: '#a0a0a0',
      },
    },
    divider: {
      margin: '20px 0',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim()) {
      try {
        await submitFeedback(user?.id || 'anonymous', user?.primaryEmailAddress?.emailAddress || 'anonymous', message);
        setMessage('');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error submitting message:', error);
        alert('Er is een fout opgetreden bij het versturen van je bericht.');
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Box>
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Skinloe Logo" style={styles.logo} />
        </Link>
      </Box>
      <Box sx={styles.contentBox}>
        <Typography variant="h4" component="h2" gutterBottom sx={styles.title}>
          Contact
        </Typography>
        <Typography sx={styles.content}>
          <strong>Neem contact met ons op:</strong>
          <br />
          hallo@skinloe.nl
        </Typography>
        <Divider sx={styles.divider} />
        <form onSubmit={handleSubmit} style={styles.form}>
          <textarea
            placeholder="Je bericht"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={styles.textArea}
            required
          />
          <Button
            type="submit"
            variant="contained"
            sx={styles.submitButton}
            disabled={!message.trim()}
          >
            Versturen
          </Button>
        </form>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Bedankt voor je bericht!"
      />
    </Container>
  );
};

export default Contact;