import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchRoutineById } from './api';
import { Container, Typography, Box, Paper, Button, useTheme, Modal } from '@mui/material';
import logo from './assets/logo.png';

function RoutineView() {
  const [routine, setRoutine] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const theme = useTheme();

  useEffect(() => {
    const loadRoutine = async () => {
      try {
        setLoading(true);
        const fetchedRoutine = await fetchRoutineById(id);
        console.log("Fetched routine in RoutineView:", fetchedRoutine);
        setRoutine(fetchedRoutine);
        setLoading(false);
      } catch (err) { 
        console.error('Error fetching routine:', err);
        if (err.response) {
          console.error('Response data:', err.response.data);
          console.error('Response status:', err.response.status);
        } else if (err.request) {
          console.error('No response received:', err.request);
        } else {
          console.error('Error setting up request:', err.message);
        }
        setError('Kon de routine niet laden. Probeer het later opnieuw.');
        setLoading(false);
      }
    };

    loadRoutine();
  }, [id]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (loading) return <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Laden...</Typography>;
  if (error) return <Typography color="error" sx={{ fontFamily: theme.typography.body1.fontFamily }}>{error}</Typography>;
  if (!routine) return <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Geen routine gevonden.</Typography>;

  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Link to="/">
          <img src={logo} alt="Skinloe Logo" style={{ width: '350px', marginBottom: '20px' }} />
        </Link>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: theme.typography.h4.fontFamily }}>
          {routine.routineName}
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 3, borderRadius: '8px' }}>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: theme.typography.h6.fontFamily }}>Details:</Typography>
        <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Huidtype: {routine.skinType}</Typography>
        <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Complexiteit: {routine.routineComplexity}</Typography>
        <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Leeftijdscategorie: {routine.ageRange}</Typography>
        <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Huiddoelen: {Array.isArray(routine.skinGoals) ? routine.skinGoals.join(', ') : 'Niet gespecificeerd'}</Typography>
        <Typography variant="h6" sx={{ mt: 2, mb: 1, fontFamily: theme.typography.h6.fontFamily }}>Routine:</Typography>
        <Box dangerouslySetInnerHTML={{ __html: routine.routine }} sx={{ 
          '& p': { margin: '0.5em 0', fontFamily: theme.typography.body1.fontFamily },
          '& br': { display: 'block', content: '""', marginTop: '0.5em' }
        }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button 
            onClick={handleOpenModal}
            className="custom-button"
          >
            Bekijk meer aanbevolen producten
          </Button>
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button 
          component={Link} 
          to="/opgeslagen-routines"
          className="custom-button"
        >
          Terug naar opgeslagen routines
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="more-products-modal"
        aria-describedby="modal-with-more-recommended-products"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="more-products-modal" variant="h6" component="h2">
            Meer aanbevolen producten
          </Typography>
          {/* Add more product recommendations here */}
        </Box>
      </Modal>
    </Container>
  );
}

export default RoutineView;