import React from 'react';
import { Box, Container, Typography, useTheme, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

const Privacyverklaring = () => {
  const theme = useTheme();

  const styles = {
    container: {
      textAlign: 'center',
      backgroundColor: '#FCFCFC',
    },
    logo: {
      width: '250px',
      marginBottom: '20px',
    },
    contentBox: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '32px',
    },
    title: {
      color: '#524C4C',
      marginBottom: '20px',
      fontFamily: theme.typography.h4.fontFamily,
    },
    content: {
      color: '#524C4C',
      textAlign: 'left',
      fontFamily: theme.typography.body1.fontFamily,
    },
    section: {
      marginBottom: '16px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: '8px',
      fontFamily: theme.typography.h6.fontFamily,
    },
    list: {
      paddingLeft: '20px',
    },
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Box>
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Skinloe Logo" style={styles.logo} />
        </Link>
      </Box>
      <Box sx={styles.contentBox}>
        <Typography variant="h4" component="h1" gutterBottom sx={styles.title}>
          Privacyverklaring
        </Typography>
        <Box sx={styles.content}>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Ingangsdatum: 22-Jul-2024</Typography>
            <Typography sx={styles.sectionTitle}>Laatst bijgewerkt: 22-Jul-2024</Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography>
              Deze Privacyverklaring beschrijft het beleid van Skinloe.nl met betrekking tot het verzamelen, gebruiken en openbaar maken van uw informatie wanneer u onze website (skinloe.nl) gebruikt (de "Service"). Door de Service te gebruiken, stemt u in met het verzamelen, gebruiken en openbaar maken van uw informatie in overeenstemming met deze Privacyverklaring. Als u hier niet mee instemt, gebruik de Service dan niet.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography>
              We kunnen deze Privacyverklaring op elk moment wijzigen zonder voorafgaande kennisgeving aan u. De herziene Privacyverklaring zal worden geplaatst op de Service. De herziene Privacyverklaring treedt in werking 180 dagen nadat deze op de Service is geplaatst, en uw voortgezette toegang tot of gebruik van de Service na die tijd vormt uw aanvaarding van de herziene Privacyverklaring. We raden u daarom aan deze pagina regelmatig te bekijken.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Hoe we uw informatie gebruiken:</Typography>
            <Typography>
              We gebruiken de informatie die we over u verzamelen voor de volgende doeleinden:
            </Typography>
            <List sx={styles.list}>
              <ListItem>Marketing/Promotie</ListItem>
              <ListItem>Gebruikersaccount aanmaken</ListItem>
              <ListItem>Klantfeedback verzamelen</ListItem>
              <ListItem>Ondersteuning</ListItem>
              <ListItem>Administratieve informatie</ListItem>
              <ListItem>Gerichte advertenties</ListItem>
              <ListItem>Gebruiker-naar-gebruiker opmerkingen</ListItem>
              <ListItem>Gebruikersaccount beheren</ListItem>
            </List>
            <Typography>
              Als we uw informatie voor een ander doel willen gebruiken, zullen we u om toestemming vragen en uw informatie alleen gebruiken na ontvangst van uw toestemming en dan alleen voor het doel(en) waarvoor u toestemming geeft, tenzij we wettelijk verplicht zijn anders te handelen.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Uw rechten:</Typography>
            <Typography>
              Afhankelijk van de toepasselijke wetgeving heeft u mogelijk het recht om uw persoonsgegevens in te zien en te corrigeren of te wissen, een kopie van uw persoonsgegevens te ontvangen, de actieve verwerking van uw gegevens te beperken of hiertegen bezwaar te maken, ons te vragen uw persoonlijke informatie te delen (overdragen) naar een andere entiteit, elke toestemming die u ons heeft gegeven voor het verwerken van uw gegevens in te trekken, een klacht in te dienen bij een toezichthoudende autoriteit en andere rechten die relevant kunnen zijn onder de toepasselijke wetgeving. Om deze rechten uit te oefenen, kunt u ons schrijven op hallo@skinloe.nl. We zullen op uw verzoek reageren in overeenstemming met de toepasselijke wetgeving.
            </Typography>
            <Typography sx={{ marginTop: '8px' }}>
              Houd er rekening mee dat als u ons niet toestaat de vereiste persoonlijke informatie te verzamelen of te verwerken of de toestemming voor het verwerken ervan voor de vereiste doeleinden intrekt, u mogelijk geen toegang heeft tot of gebruik kunt maken van de diensten waarvoor uw informatie werd gevraagd.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Cookies etc.</Typography>
            <Typography>
              Voor meer informatie over hoe we deze gebruiken en uw keuzes met betrekking tot deze trackingtechnologieën, verwijzen we u naar ons <Link to="/cookies">Cookiebeleid</Link>.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Beveiliging:</Typography>
            <Typography>
              De beveiliging van uw informatie is belangrijk voor ons en we zullen redelijke beveiligingsmaatregelen nemen om het verlies, misbruik of ongeoorloofde wijziging van uw informatie onder onze controle te voorkomen. Gezien de inherente risico's kunnen we echter geen absolute veiligheid garanderen en kunnen we bijgevolg de veiligheid van informatie die u aan ons overdraagt niet verzekeren of waarborgen en u doet dit op eigen risico.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Links naar derden & gebruik van uw informatie:</Typography>
            <Typography>
              Onze Service kan links bevatten naar andere websites die niet door ons worden beheerd. Deze Privacyverklaring heeft geen betrekking op het privacybeleid en andere praktijken van derden, inclusief derden die websites of diensten beheren die toegankelijk kunnen zijn via een link op de Service. We raden u ten zeerste aan het privacybeleid te bekijken van elke site die u bezoekt. We hebben geen controle over en nemen geen verantwoordelijkheid voor de inhoud, het privacybeleid of de praktijken van websites of diensten van derden.
            </Typography>
          </Box>
          <Box sx={styles.section}>
            <Typography sx={styles.sectionTitle}>Klachten / Functionaris voor gegevensbescherming:</Typography>
            <Typography>
              Als u vragen of zorgen heeft over de verwerking van uw informatie die bij ons beschikbaar is, kunt u een e-mail sturen naar onze Functionaris voor gegevensbescherming op skinloe.nl, e-mail: hallo@skinloe.nl. We zullen uw zorgen behandelen in overeenstemming met de toepasselijke wetgeving.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Privacyverklaring;