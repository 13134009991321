const fontCombinations = [
    {
      name: "Playfair Display + Source Sans Pro",
      primary: '"Source Sans Pro", sans-serif',
      secondary: '"Playfair Display", serif'
    },
    {
      name: "Montserrat + Lora",
      primary: '"Lora", serif',
      secondary: '"Montserrat", sans-serif'
    },
    {
      name: "Roboto Slab + Roboto",
      primary: '"Roboto", sans-serif',
      secondary: '"Roboto Slab", serif'
    },
    {
      name: "Poppins + Open Sans",
      primary: '"Open Sans", sans-serif',
      secondary: '"Poppins", sans-serif'
    },
    {
      name: "Raleway + Merriweather",
      primary: '"Merriweather", serif',
      secondary: '"Raleway", sans-serif'
    }
  ];
  
  // Change this index to test different font combinations
  export const selectedFontIndex = 0; // This will use Roboto Slab + Roboto
  
  export const selectedFontCombination = fontCombinations[selectedFontIndex];