import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import { Close as CloseIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const Fact = ({ facts, onClose, isMobile }) => {
  const theme = useTheme();
  const [currentFact, setCurrentFact] = useState('');

  const getRandomFact = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * facts.length);
    return facts[randomIndex].fact;
  }, [facts]);

  useEffect(() => {
    if (!isMobile) {
      setCurrentFact(getRandomFact());
    }
  }, [getRandomFact, isMobile]);

  const handleRefresh = () => {
    setCurrentFact(getRandomFact());
  };

  return !isMobile && (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        maxWidth: 300,
        padding: 2,
        backgroundColor: '#D4A5A5',
        color: theme.palette.getContrastText('#D4A5A5'),
        zIndex: 1000,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Wist je dat?
        </Typography>
        <Box>
          <IconButton size="small" onClick={handleRefresh} color="inherit">
            <RefreshIcon />
          </IconButton>
          <IconButton size="small" onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Typography variant="body2">{currentFact}</Typography>
    </Paper>
  );
};

export default Fact;