import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const ComingSoonPage = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', backgroundColor: '#FCFCFC' }}>
      <Box mt={4}>
        <Link to="/">
          <img 
            src={`${process.env.PUBLIC_URL}/logo.png`} 
            alt="Skinloe Logo" 
            style={{ width: '350px', marginBottom: '2px', cursor: 'pointer' }} 
          />
        </Link>
      </Box>
      <Typography 
        variant="h6" 
        gutterBottom
        sx={{ fontFamily: theme.typography.h6.fontFamily }}
      >
        Functie komt binnenkort!
      </Typography>
    </Container>
  );
};

export default ComingSoonPage;