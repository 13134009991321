import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { collectEmail } from './api';
import './EmailCollection.css';

const EmailCollection = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await collectEmail(email);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  };

  return (
    <div className="email-collection" style={{ fontFamily: theme.typography.fontFamily }}>
      <h3 style={{ 
        fontFamily: theme.typography.h6.fontFamily,
        fontSize: '1.2em',
        marginBottom: '15px'
      }}>
        Skinloe groeit snel - schrijf je in
      </h3>
      {!isSubmitted ? (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Voer uw e-mailadres in"
            required
          />
          <button type="submit">Aanmelden</button>
        </form>
      ) : (
        <p className="success-message">Bedankt voor het inschrijven!</p>
      )}
    </div>
  );
};

export default EmailCollection;