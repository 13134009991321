export const isMobilePhone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobileRegex = /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i;
  
  // Check if it's a mobile device
  const isMobile = mobileRegex.test(userAgent.toLowerCase());
  
  // Check if the screen width is less than 480px (typical phone width)
  const isSmallScreen = window.innerWidth < 480;
  
  return isMobile && isSmallScreen;
};