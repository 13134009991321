import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { fetchSavedRoutines, updateRoutineName } from './api';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, List, ListItem, TextField, Paper, useTheme } from '@mui/material';
import logo from './assets/logo.png'; // Adjust the path as needed

const NoRoutinesMessage = () => {
  const theme = useTheme();
  return (
    <Typography variant="body1" sx={{ textAlign: 'center', mt: 4, fontFamily: theme.typography.body1.fontFamily }}>
      Hier verschijnen je opgeslagen routines - genereer en sla er eentje op!
    </Typography>
  );
};

function SavedRoutines() {
  const { user } = useUser();
  const [savedRoutines, setSavedRoutines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [newName, setNewName] = useState('');
  const [hasRoutines, setHasRoutines] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchRoutines = async () => {
      if (!user) {
        console.log("No user found");
        return;
      }
      try {
        setLoading(true);
        const userEmail = user.primaryEmailAddress.emailAddress;
        console.log("Fetching routines for email:", userEmail);
        const routines = await fetchSavedRoutines(userEmail);
        console.log("Fetched routines (raw):", routines);
        setSavedRoutines(Array.isArray(routines) ? routines : []);
        setHasRoutines(routines.length > 0);
        console.log("Updated savedRoutines state:", Array.isArray(routines) ? routines : []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching saved routines:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
        setError('Kon opgeslagen routines niet ophalen. Probeer het later opnieuw.');
        setLoading(false);
      }
    };

    if (user) {
      fetchRoutines();
    }
  }, [user]);

  const handleView = (routineId) => {
    navigate(`/routine/${routineId}`);
  };

  const handleEdit = (id, currentName) => {
    setEditingId(id);
    setNewName(currentName);
  };

  const handleSave = async (id) => {
    try {
      await updateRoutineName(id, newName);
      setSavedRoutines(savedRoutines.map(routine => 
        routine._id === id ? { ...routine, routineName: newName } : routine
      ));
      setEditingId(null);
    } catch (error) {
      console.error('Error updating routine name:', error);
      setError('Kon de naam van de routine niet bijwerken. Probeer het later opnieuw.');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Link to="/">
          <img src={logo} alt="Skinloe Logo" style={{ width: '350px', marginBottom: '20px' }} />
        </Link>
        <Typography variant="h5" component="h1" gutterBottom sx={{ fontFamily: theme.typography.h5.fontFamily }}>
          Opgeslagen Routines
        </Typography>
      </Box>
      {loading ? (
        <Typography sx={{ fontFamily: theme.typography.body1.fontFamily }}>Laden...</Typography>
      ) : error ? (
        <Typography color="error" sx={{ fontFamily: theme.typography.body1.fontFamily }}>{error}</Typography>
      ) : hasRoutines ? (
        <List sx={{ width: '100%' }}>
          {savedRoutines.map((routine) => (
            <Paper key={routine._id} elevation={3} sx={{ mb: 2, p: 2, borderRadius: '8px' }}>
              <ListItem alignItems="flex-start" sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1 }}>
                  {editingId === routine._id ? (
                    <TextField
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          fontFamily: theme.typography.body1.fontFamily,
                          '& fieldset': {
                            borderColor: '#D4A5A5',
                          },
                          '&:hover fieldset': {
                            borderColor: '#C59494',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#C59494',
                          },
                        },
                      }}
                    />
                  ) : (
                    <Typography variant="h6" sx={{ fontFamily: theme.typography.h6.fontFamily, fontWeight: 'bold' }}>
                      {routine.routineName}
                    </Typography>
                  )}
                  <Typography component="p" variant="body2" sx={{ fontFamily: theme.typography.body2.fontFamily, color: '#666' }}>
                    {routine.skinType}, {routine.routineComplexity}, Leeftijd: {routine.ageRange}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 2 }}>
                  {editingId === routine._id ? (
                    <Button 
                      onClick={() => handleSave(routine._id)} 
                      sx={{
                        backgroundColor: '#D4A5A5',
                        color: '#524C4C',
                        fontFamily: theme.typography.button.fontFamily,
                        mb: 1,
                        width: '100px',
                        '&:hover': {
                          backgroundColor: '#C59494',
                        },
                      }}
                    >
                      Opslaan
                    </Button>
                  ) : (
                    <Button 
                      onClick={() => handleEdit(routine._id, routine.routineName)} 
                      sx={{
                        backgroundColor: '#D4A5A5',
                        color: '#524C4C',
                        fontFamily: theme.typography.button.fontFamily,
                        mb: 1,
                        width: '100px',
                        '&:hover': {
                          backgroundColor: '#C59494',
                        },
                      }}
                    >
                      Bewerken
                    </Button>
                  )}
                  <Button 
                    onClick={() => handleView(routine._id)} 
                    sx={{
                      backgroundColor: '#D4A5A5',
                      color: '#524C4C',
                      fontFamily: theme.typography.button.fontFamily,
                      width: '100px',
                      '&:hover': {
                        backgroundColor: '#C59494',
                      },
                    }}
                  >
                    Bekijken
                  </Button>
                </Box>
              </ListItem>
            </Paper>
          ))}
        </List>
      ) : (
        <NoRoutinesMessage />
      )}
    </Container>
  );
}

export default SavedRoutines;