import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, Typography, Box, List, ListItem, Avatar, Paper, useMediaQuery } from '@mui/material';
import { createThread, addMessage, runAssistant } from './api';
import styles from './SkinloeAIAssistent.module.css';
import FeedbackForm from './FeedbackForm';
import { useTheme } from '@mui/material/styles';

const SkinloeAIAssistent = ({ isSignedIn }) => {
  const [threadId, setThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const messageListRef = useRef(null);
  const inputRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('Is mobile:', isMobile); // Add this line for debugging

  useEffect(() => {
    async function initThread() {
      const newThreadId = await createThread();
      setThreadId(newThreadId);
    }
    initThread();
  }, []);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    setIsLoading(true);
    setMessages([...messages, { role: 'user', content: input }]);
    setInput('');

    await addMessage(threadId, input);
    const response = await runAssistant(threadId);

    setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: response }]);
    setIsLoading(false);
    
    // Set focus to the input field and ensure it's ready for typing
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.click();
        inputRef.current.setSelectionRange(0, 0);
      }
    }, 0);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <Container maxWidth={false} disableGutters className={styles.container}>
        <Typography variant="h5" component="h1" gutterBottom align="center" style={{ fontFamily: theme.typography.h5.fontFamily }}>
          Skinloe AI assistent
        </Typography>
        <Box className={`${styles.chatContainer} ${isMobile ? styles.mobileChatContainer : ''}`}>
          {messages.length === 0 && (
            <Paper elevation={3} className={styles.welcomeMessage}>
              <Typography variant="h6" gutterBottom style={{ fontFamily: theme.typography.h6.fontFamily, color: '#524C4C' }}>
                Welkom bij Sharon
              </Typography>
              <Typography variant="body1" style={{ fontFamily: theme.typography.body1.fontFamily, color: '#6B6B6B' }}>
                Dit is Sharon, de Skinloe AI-assistent, die graag al uw huidverzorgingsvragen beantwoordt.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: theme.typography.body2.fontFamily, color: '#8A8A8A', marginTop: '16px' }}>
                Je berichten blijven privé, niemand kan ze lezen.
              </Typography>
            </Paper>
          )}
          <List className={styles.messageList} ref={messageListRef}>
            {messages.map((message, index) => (
              <ListItem key={index} className={`${styles.messageItem} ${message.role === 'user' ? styles.userMessageItem : styles.aiMessageItem}`}>
                <Avatar className={message.role === 'user' ? styles.userAvatar : styles.aiAvatar}>
                  {message.role === 'user' ? 'U' : 'AI'}
                </Avatar>
                <Typography 
                  variant="body1" 
                  className={`${styles.message} ${message.role === 'user' ? styles.userMessage : styles.aiMessage}`}
                  style={{ fontFamily: theme.typography.body1.fontFamily }}
                  dangerouslySetInnerHTML={{ __html: message.content }}
                />
              </ListItem>
            ))}
            {isLoading && (
              <ListItem className={`${styles.messageItem} ${styles.aiMessageItem}`}>
                <Avatar className={styles.aiAvatar}>AI</Avatar>
                <Typography variant="body1" className={`${styles.message} ${styles.aiMessage}`} style={{ fontFamily: theme.typography.body1.fontFamily }}>
                  Sharon is aan het typen...
                </Typography>
              </ListItem>
            )}
          </List>
          <Box className={`${styles.inputBox} ${isMobile ? styles.mobileInputBox : ''}`}>
            <TextField
              inputRef={inputRef}
              fullWidth
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Stel je vraag over huidverzorging..."
              disabled={isLoading}
              className={`${styles.textField} ${isMobile ? styles.mobileTextField : ''}`}
              InputProps={{
                onFocus: (event) => event.target.setSelectionRange(event.target.value.length, event.target.value.length),
                style: { backgroundColor: '#FCFCFC' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#524C4C',
                    borderWidth: '1.5px',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleSendMessage}
              disabled={isLoading || input.trim() === ''}
              className={`${styles.button} ${isMobile ? styles.mobileButton : ''}`}
            >
              Verstuur
            </Button>
          </Box>
        </Box>
      </Container>
      {!isMobile && <FeedbackForm />}
    </Box>
  );
};

export default SkinloeAIAssistent;