const isLocalhost = () => {
    return window.location.hostname === 'localhost' || 
           window.location.hostname === '127.0.0.1' ||
           window.location.hostname.includes('192.168.');
};

export const trackPageView = (path, title) => {
  if (isLocalhost()) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: {
      path,
      title
    }
  });
};

export const trackEvent = (category, action, label) => {
  if (isLocalhost()) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'customEvent',
    category,
    action,
    label
  });
};

export const initializeAnalytics = () => {
  // This function is now empty as GTM initialization is handled elsewhere
  // We keep it to maintain compatibility with existing code
};